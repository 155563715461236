import { LayoutGridContainer, LayoutGridItem, DatePickerSingle } from '@madpaws/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { UPDATE_FILTER_REPEAT_WEEKLY_CALENDAR_EVENT_NAME } from '~/components/analytics/constants';

import { WeekDaysSelector } from './WeekDaysSelector';
import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { DateFormat } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type RwbDatesSelectorProps = {
  dateFormat?: DateFormat;
  disabledDates?: string[];
  isCalendarIconHidden?: boolean;
  isLabelVisuallyHidden?: boolean;
  name?: string;
  placeholder?: string;
};

export const RwbDatesSelector = ({
  dateFormat = 'short',
  disabledDates = [],
  isCalendarIconHidden = true,
  isLabelVisuallyHidden,
  name,
  placeholder,
}: RwbDatesSelectorProps): ReactElement => {
  const {
    values: {
      chronology: { rwbStartDate },
    },
    setFieldValue,
  } = useFormikContext<SearchFilters>();

  const onDateSelection = (date: string): void => {
    setFieldValue(KEYS.rwbStartDate, date);
    trackEvent(UPDATE_FILTER_REPEAT_WEEKLY_CALENDAR_EVENT_NAME);
  };

  return (
    <LayoutGridContainer columnGap={{ small: 'default', medium: 'default', large: 'small2x' }}>
      <LayoutGridItem placement={[1, 7]}>
        <WeekDaysSelector
          isCalendarIconHidden={isCalendarIconHidden}
          isLabelVisuallyHidden={isLabelVisuallyHidden}
          name={name}
          placeholder={placeholder}
        />
      </LayoutGridItem>
      <LayoutGridItem placement={[7, 13]}>
        <DatePickerSingle
          dateFormat={dateFormat}
          daysOfWeek={[1]}
          disabledDates={disabledDates}
          hasTighterSpacing
          isCalendarIconHidden={isCalendarIconHidden}
          isLabelVisuallyHidden={isLabelVisuallyHidden}
          isRightAligned
          label="Start week"
          name={name ? name : KEYS.rwbStartDate}
          onDateSelection={onDateSelection}
          placeholder="Start week"
          value={rwbStartDate}
        />
      </LayoutGridItem>
    </LayoutGridContainer>
  );
};
