import { TextField, VisuallyHidden, Dialog } from '@madpaws/design-system';
import React, { useState, useRef, useEffect, useCallback } from 'react';

import { DOM_CONTAINER_ID } from '~/components/constants';

import type { ReactElement, ReactNode, MouseEvent } from 'react';

type Props = {
  children: ReactNode;
  displayValue: string;
  handleClose?: () => void;
  handleSubmit?: (event: MouseEvent<HTMLButtonElement>) => void;
  icon?: ReactElement;
  isLabelVisuallyHidden?: boolean;
  label: string;
  placeholder?: string;
  visuallyHiddenLabel: string;
};

export const DialogFormField = ({
  children,
  displayValue,
  handleClose,
  handleSubmit,
  icon,
  isLabelVisuallyHidden = false,
  label,
  placeholder,
  visuallyHiddenLabel,
}: Props): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const textFieldInputRef = useRef<HTMLInputElement>(null);

  const handleDialogOpen = (): void => setIsDialogOpen(true);
  const handleDialogClose = (): void => {
    if (handleClose) {
      handleClose();
    }

    setIsDialogOpen(false);
  };
  const handleCtaClick = (event: MouseEvent<HTMLButtonElement>): void => {
    if (handleSubmit) {
      handleSubmit(event);
    }
    handleDialogClose();
  };

  const handleTextFieldKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleDialogOpen();
    }
  }, []);

  useEffect(() => {
    const textFieldInput = textFieldInputRef.current;
    if (!textFieldInput) {
      return (): void => {};
    }

    textFieldInput.addEventListener('keypress', handleTextFieldKeyPress);

    return (): void => {
      textFieldInput.removeEventListener('keypress', handleTextFieldKeyPress);
    };
  }, [handleTextFieldKeyPress]);

  return (
    <>
      <TextField
        hasTighterSpacing
        icon={icon}
        isLabelVisuallyHidden={isLabelVisuallyHidden}
        isReadOnly
        label={label}
        name={label.toLowerCase()}
        onChange={(): void => {}}
        onClick={handleDialogOpen}
        placeholder={placeholder}
        ref={textFieldInputRef}
        value={displayValue}
      />
      <VisuallyHidden>{visuallyHiddenLabel}</VisuallyHidden>
      <Dialog
        cta={{
          label: 'Done',
          onClick: handleCtaClick,
        }}
        domContainerId={DOM_CONTAINER_ID}
        heading={label}
        isOpen={isDialogOpen}
        onRequestClose={handleDialogClose}
      >
        {children}
      </Dialog>
    </>
  );
};
