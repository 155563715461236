import { RadioGroup } from '@madpaws/design-system';
import React from 'react';

import { SERVICE_OPTIONS } from '~/components/constants';

import type { RadioGroupOnChange } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { ServiceOption } from '~/components/constants';

type Props = {
  legend: string;
  name: string;
  onChange: (event: RadioGroupOnChange) => void;
  options?: ServiceOption[];
  value: string;
};

export const ServiceTypeSelector = ({
  onChange,
  legend,
  name,
  value,
  options = SERVICE_OPTIONS,
}: Props): ReactElement => (
  <RadioGroup
    legend={legend}
    name={name}
    onChange={onChange}
    options={options}
    value={{ value }}
    variant="tiledWithImage"
  />
);
