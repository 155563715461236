import { DatePickerRange } from '@madpaws/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { UPDATE_FILTER_OVERNIGHT_CALENDAR_EVENT_NAME } from '~/components/analytics/constants';

import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { DateRange, DateFormat } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type OvernightDatesSelectorProps = {
  dateFormat?: DateFormat;
  disabledDates?: string[];
  isCalendarIconHidden?: boolean;
  isLabelVisuallyHidden?: boolean;
  nameFrom?: string;
  nameTo?: string;
};

export const OvernightDatesSelector = ({
  dateFormat = 'short',
  disabledDates = [],
  isCalendarIconHidden = true,
  isLabelVisuallyHidden,
  nameFrom,
  nameTo,
}: OvernightDatesSelectorProps): ReactElement => {
  const {
    values: {
      chronology: { startDate, endDate },
    },
    setFieldValue,
  } = useFormikContext<SearchFilters>();

  const onDateSelection = (range: DateRange): void => {
    const { from, to } = range;

    if (from && from !== startDate) {
      setFieldValue(KEYS.startDate, from);
    }

    if (to && to !== endDate) {
      setFieldValue(KEYS.endDate, to);
    }

    trackEvent(UPDATE_FILTER_OVERNIGHT_CALENDAR_EVENT_NAME);
  };

  return (
    <DatePickerRange
      dateFormat={dateFormat}
      disabledDates={disabledDates}
      hasTighterSpacing
      isCalendarIconHidden={isCalendarIconHidden}
      isFieldsetLegendVisuallyHidden
      isLabelVisuallyHidden={isLabelVisuallyHidden}
      isSideBySide
      labelFrom="From"
      labelTo="Until"
      legend="What dates would you like to book?"
      nameFrom={nameFrom ? nameFrom : KEYS.startDate}
      nameTo={nameTo ? nameTo : KEYS.endDate}
      onDateSelection={onDateSelection}
      placeholderFrom="From"
      placeholderTo="Until"
      value={{ from: startDate ?? null, to: endDate ?? null }}
    />
  );
};
