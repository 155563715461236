import { RadioGroup } from '@madpaws/design-system';
import { Field, useFormikContext } from 'formik';
import React from 'react';

import { BOOKING_TYPE_MARKETPLACE, BOOKING_TYPE_RWB } from '~/components/utils/search';

import { RWB_SERVICE_NAMES, SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { RadioGroupOnChange } from '@madpaws/design-system';
import type { FieldProps } from 'formik';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

export const BookingTypeField = (): ReactElement => {
  const { setFieldValue } = useFormikContext<SearchFilters>();

  const handleChange = (event: RadioGroupOnChange): void => {
    const {
      target: {
        value: { value: fieldValue },
      },
    } = event;

    setFieldValue(KEYS.bookingType, fieldValue);
  };

  return (
    <Field name={KEYS.bookingType}>
      {({ field: { value }, form }: FieldProps): ReactElement | null => {
        if (!RWB_SERVICE_NAMES.includes(form.values.service.type)) {
          return null;
        }

        return (
          <RadioGroup
            legend="How often do you need it?"
            name={KEYS.bookingType}
            onChange={handleChange}
            options={[
              { value: BOOKING_TYPE_MARKETPLACE, label: 'One-off' },
              { value: BOOKING_TYPE_RWB, label: 'Repeat weekly' },
            ]}
            value={{ value }}
            variant="tiled"
          />
        );
      }}
    </Field>
  );
};
