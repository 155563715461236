import { Field, useFormikContext } from 'formik';
import React from 'react';

import { ServiceTypeSelector } from './ServiceTypeSelector';
import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { RadioGroupOnChange } from '@madpaws/design-system';
import type { FieldProps } from 'formik';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type { ServiceOption } from '~/components/constants';

type Props = {
  options?: ServiceOption[];
};

export const ServiceTypeField = ({ options }: Props): ReactElement => {
  const { setFieldValue } = useFormikContext<SearchFilters>();

  const handleChange = (event: RadioGroupOnChange): void => {
    const {
      target: {
        value: { value: fieldValue },
      },
    } = event;

    setFieldValue(KEYS.serviceType, fieldValue);
  };

  return (
    <Field name={KEYS.serviceType}>
      {({ field }: FieldProps): ReactElement => {
        const { name, value } = field;
        return (
          <ServiceTypeSelector
            legend="What service are you looking for?"
            name={name}
            onChange={handleChange}
            options={options}
            value={value}
          />
        );
      }}
    </Field>
  );
};
